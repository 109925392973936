import React from "react";
import PropTypes from "prop-types";

const statusErrors = {
  401: <span>This link has expired. If you still need access or believe this is a mistake, please reach out at support@turn.ai for assistance.</span>,
  403: <span>Error 403: This candidate link has already been used.</span>,
  404: <span>Error 404: That partner does not exist.</span>,
  400: (
    <span>Error 400: The partner name is incorrect. Maybe check spelling?</span>
  ),
  422: (
    <span>
      You must configure a primary contact in order to access this
      functionality.
    </span>
  ),
  500: (
    <span>
      Error 500: Internal Server Error.
      <br />
      No worries, service will resume shortly.
    </span>
  )
};

const StatusErrors = ({ status }) => {
  const error = statusErrors[status];
  if (!error)
    return (
      <span>
        Error 520: Unknown Error.
        <br />
        We are not sure what happened, please try again.
      </span>
    );
  return error;
};

StatusErrors.propTypes = {
  status: PropTypes.number
};

export default StatusErrors;
