/* eslint-disable no-undef */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, Provider } from "mobx-react";
import { create } from "mobx-persist";
import Pusher from "react-pusher";
import queryString from "query-string";
import { turnAPI } from "../../services";
import { database } from "../../index";
import NotFound from "../ErrorPage";
import { TurnLoader } from "../../components/Loader";
import LandingPageModel from "../../models/landing-page";
import EditCandidateDetails from "../../components/LandingPage/edit-candidate-details";
import CandidateReportModel from "../../models/candidate";
import CandidateReport from "../Candidate/Report";

import ReportsProcessing from "../../components/BackgroundReport/reports-processing";
// import validateDriversLicense from 'turn-shared'
import StatusErrors from "./status-errors";
import { pusherClient } from "../../index";
import _ from "lodash";
import { Detector } from "react-detect-offline";
import { formatEmail, parseQuery } from "turn-shared";
import ConsentProvided from "../../components/LandingPage/consent-provided";
import { SSN_TRACE_MULTIPLE_ERROR, SSN_MESSAGE_ERROR } from "../../constants";

const CONSENT_END_DELAY = Number(process.env.REACT_APP_CONSENT_END_DELAY);
const REDIRECT_TO_REPORT_DELAY = Number(
  process.env.REACT_APP_REDIRECT_TO_REPORT_DELAY
);
let consentEndTimeOut = null;

class BGCLandingPage extends Component {
  static propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
    email_id: PropTypes.string,
    recheck_id: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      validEmail: true,
      errorMessage: "",
      timeout: false,
      isAsync: false,
      consentEnd: false,
      isOnline: true,
      recheck_id: null
    };
    this.model = this.createHydrate(
      "modelState",
      new LandingPageModel(database)
    );
    this.report = new CandidateReportModel();
  }

  componentDidMount() {
    var urlParams = new URLSearchParams(this.props.router.location.search);
    this.setState({ isAsync: urlParams.get("async") });
    const query = parseQuery(this.props.router.location.search);
    if (query.redirect_url) {
      localStorage.setItem("redirect_url", query.redirect_url);
    }
    this.model.getNicknames();

    let isRecheckValid = false;
    // Validate recheck id and set bypass_dupe to true if valid
    if (
      query.recheck_id &&
      typeof query.recheck_id === "string" &&
      /^\d+$/.test(query.recheck_id)
    ) {
      isRecheckValid = true;
    }
    this.model.setBypass(query.bypass_dupe === "true" || isRecheckValid);
  }
  UNSAFE_componentWillMount() {
    this.email_id = this.props.email_id || this.props.params.email_id;
    this.getCandidateData(this.email_id);
    const { step } = JSON.parse(localStorage.getItem("modelState")) || {};
    const { showManualForm } =
      JSON.parse(localStorage.getItem("modelState")) || {};
    const { showAutomaticFill } =
      JSON.parse(localStorage.getItem("modelState")) || {};
    this.report.candidateConsentEmailId = this.email_id;
    this.model.candidateConsentEmailId = this.email_id;
    this.model.step = step || this.model.step;
    this.model.showManualForm = showManualForm || this.model.showManualForm;
    this.model.showAutomaticFill =
      showAutomaticFill || this.model.showAutomaticFill;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOnline === true && prevState.isOnline === false) {
      setTimeout(() => {
        // window.location.reload();
      }, 500);
    }
  }

  createHydrate = (name, model) => {
    const hydrate = create();

    hydrate(name, model);
    return model;
  };

  getCandidateData = async email_id => {
    const { model } = this;
    model.setLoading(true);
    const response = await turnAPI.getCandidateData(email_id);
    const query = queryString.parse(this.props.router.location.search);
    const { data } = response;
    const { email } = JSON.parse(localStorage.getItem("modelState")) || {};
    const { gender } = JSON.parse(localStorage.getItem("modelState")) || {};
    const { hasMiddleName } =
      JSON.parse(localStorage.getItem("modelState")) || {};
    switch (response.status) {
      case 200:
        if (!response.data) this.setState({ validEmail: false });
        if (Array.isArray(data.required_fields))
          model.setRequiredFields(data.required_fields);
        if (!_.isEmpty(query.email)) model.freezeEmail();
        model.setOneOffChecks(data.one_off_checks);
        model.setEmail(formatEmail(data.email || query.email || email));
        model.setPhone(data.phone_number || query.phone_number);
        model.setPartnerName(data.partner_name);
        model.setUserId(data.user_id);
        model.setCandidateId(email_id);
        model.setCandidateUUID(response.data.partner_worker_uuid);
        model.setDoMVR(data.do_mvr);
        model.setSource(data.email_source);
        model.setFormData(query, data.do_mvr, model.requiredFields);
        model.setHasMiddleName(hasMiddleName || model.hasMiddleName);
        model.setGender(gender || model.gender);
        model.setTurnId(response.data.turn_id || "");
        this.report.setQueryParams(this.props.router.location.search);
        this.report.setRedirectUrl(query.redirect_url);

        await model.getLegalData();
        break;
      default:
        this.handleStatusErrors(response.status);
        break;
    }
    model.setLoading(false);
  };

  handleStatusErrors = status => {
    this.setState({
      errorMessage: <StatusErrors status={status} />,
      validEmail: false
    });
  };

  validateForm = async () => {
    const { model } = this;
    model.setLoading(true);
    window.scrollTo(0, 0);

    if (!model.skipValidation) {
      const validEmailPromise = this.validateEmail();
      const validSsnPromise = this.validateSSN();
      const [validEmail, validSsn] = await Promise.all([
        validEmailPromise,
        validSsnPromise
      ]);

      if (!validEmail || !validSsn) {
        model.setLoading(false);
        return false;
      }
    }

    await model.setSummaryOfRights();

    model.setLoading(false);
    model.clearError();

    return true;
  };

  handleProvideCandidateConsent = config => {
    return this.state.isAsync
      ? this.provideCandidateConsentAsync(config)
      : this.provideCandidateConsent(config);
  };

  provideCandidateConsent = async config => {
    this.model.setLoading(true);
    this.model.clearError();
    window.scrollTo(0, 0);
    this.model.setValidNames();
    if (!config.skipValidateSSN) {
      const validSSN = await this.validateSSN();
      if (!validSSN) {
        this.model.setLoading(false);
        return;
      }
    }

    this.model.setHasMiddleName(!this.model.has_middle_name);

    let response;

    if (this.props.recheck_id && this.props.recheck_id !== null) {
      // Combine recheck_id and model data in new object
      const payload = {
        ...this.model.data,
        ...{ recheck_id: this.props.recheck_id }
      };
      // Send request to provideCandidateRecheckConsent
      response = await turnAPI.provideCandidateRecheckConsent(
        this.props.email_id || this.props.params.email_id,
        payload
      );
    } else {
      response = await turnAPI.provideCandidateConsent(
        this.props.email_id || this.props.params.email_id,
        this.model.data
      );
    }
    switch (response.status) {
      case 200:
        this.model.setLoading(false);
        this.report.setPartner(this.model.partnerName);
        this.report.setSsnReview(response.data.ssn_review);
        this.report.setIsPendingAgeReview(response.data.age_review);
        if (this.props.recheck_id) {
          if (window.localStorage)
            window.localStorage.removeItem("candidate_id");
          if (window.sessionStorage)
            window.sessionStorage.setItem(
              "report_token",
              _.isString(this.model.phone)
                ? this.model.phone.slice(-4)
                : _.isString(this.model.ssn)
                ? this.model.ssn.slice(-4)
                : _.isString(this.model.driversLicenseNumber)
                ? this.model.driversLicenseNumber.slice(-4)
                : ""
            );
          this.props.router.push(
            `/consent/${this.props.email_id ||
              this.props.params.email_id}/report`
          );
        }
        if (!response.data.background_report) {
          this.model.setStep("0");
          this.model.setError(
            "We were unable to identify you with this data, please review your inputs and try again."
          );
          this.model.setEdit(true);
        } else {
          if (window.localStorage)
            window.localStorage.removeItem("candidate_id");
          if (window.sessionStorage)
            window.sessionStorage.setItem(
              "report_token",
              _.isString(this.model.phone)
                ? this.model.phone.slice(-4)
                : _.isString(this.model.ssn)
                ? this.model.ssn.slice(-4)
                : _.isString(this.model.driversLicenseNumber)
                ? this.model.driversLicenseNumber.slice(-4)
                : ""
            );
          this.report.setData(response.data);
          if (!this.model.doMVR && this.model.driversLicenseState !== "PA") {
            this.props.router.push(
              `/consent/${this.props.email_id ||
                this.props.params.email_id}/report`
            );
          }
        }
        window.setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
        break;
      case 409:
        this.model.setError(response.data.message);
        this.model.setStep("0");
        this.model.setEdit(true);
        break;
      case 422:
        if (response.data.ssn) {
          this.model.setError(response.data.ssn[0]);
        } else if (response.data.phone_number) {
          this.model.setError(response.data.phone_number[0]);
        } else {
          this.model.setError(response.data);
        }
        this.model.setStep("0");
        this.model.setEdit(true);
        break;
      default:
        if (!response.status || response.status === 500) {
          this.setState({ timeout: true });
          if (window.localStorage)
            window.localStorage.removeItem("candidate_id");
        } else {
          this.model.setStep("0");
          this.handleStatusErrors(response.status);
        }
        break;
    }
    this.model.setLoading(false);
  };

  provideCandidateConsentAsync = async config => {
    this.model.setLoading(true);
    this.model.clearError();
    window.scrollTo(0, 0);
    if (!config.skipValidateSSN) {
      const validSSN = await this.validateSSN();
      if (!validSSN) return;
    }
    const response = await turnAPI.provideCandidateConsentAsync(
      this.props.email_id || this.props.params.email_id,
      this.model.data
    );
    switch (response.status) {
      case 200:
        consentEndTimeOut = window.setTimeout(() => {
          if (!this.state.consentEnd) this.setState({ timeout: true });
        }, CONSENT_END_DELAY);
        break;
      case 422:
        if (response.data.ssn) {
          this.model.setError(response.data.ssn[0]);
        } else if (response.data.phone_number) {
          this.model.setError(response.data.phone_number[0]);
        } else {
          this.model.setError(response.data);
        }
        this.model.setLoading(false);
        this.model.setStep("0");
        this.model.setEdit(true);
        break;
      case 409:
        this.model.setLoading(false);
        this.model.setError(response.data.message);
        this.model.setStep("0");
        this.model.setEdit(true);
        break;
      default:
        if (!response.status || response.status === 500) {
          this.setState({ timeout: true });
          if (window.localStorage)
            window.localStorage.removeItem("candidate_id");
        } else {
          this.model.setStep("0");
          this.handleStatusErrors(response.status);
        }
        this.model.setLoading(false);
        break;
    }
  };

  submitAssent = async () => {
    this.model.setLoading(true);
    this.report.setDisableAssentButton(false);
    const response = await turnAPI.assent(
      this.props.email_id || this.props.params.email_id,
      this.report.assentData
    );
    switch (response.status) {
      case 200:
        this.report.setLoading(true);
        this.model.setConsent(true);
        break;
      default:
        this.handleStatusErrors(response.status);
        break;
    }
    this.model.setLoading(false);
    this.report.setDisableAssentButton(false);
  };

  validateEmail = async () => {
    const response = await turnAPI.validateCandidateEmail(
      this.props.email_id || this.props.params.email_id,
      this.model.email
    );
    this.model.setErrorState("email");
    switch (response.status) {
      case 200:
        if (response.data.is_verified) return true;
        this.model.setAutofocus("email");
        this.model.setErrorState(
          "email",
          "unable to verify your email, please try again."
        );
        return false;
      default:
        this.handleStatusErrors(response.status);
        return false;
    }
  };

  validateSSN = async () => {
    if (
      !this.model.requiredFields.includes("ssn") &&
      !this.model.requiredFields.includes("drivers_license_number")
    ) {
      return true;
    }

    const response = await turnAPI.validateSSN(
      this.model.firstName || undefined,
      this.model.middleName || undefined,
      this.model.lastName || undefined,
      this.model.dateOfBirth || undefined,
      this.props.email_id || this.props.params.email_id,
      this.model.numberOnlySSN || undefined,
      this.model.driversLicenseNumber || undefined,
      this.model.fountainId || undefined,
      this.model.phone || undefined,
      this.model.email || undefined,
      this.model.bypassDupe || false,
      this.model.captchaToken || undefined
    );
    this.model.setErrorState("ssn");
    switch (response.status) {
      case 200:
        if (response.data.ssn_validation) return true;
        this.model.setAutofocus("ssn");
        this.model.setErrorState(
          "ssn",
          "The SSN entered is not valid. Please try again or contact support@turn.ai for assistance."
        );
        this.model.setLoading(false);
        return false;
      case 409:
        this.model.setScrollCurrentTabTo("top");
        this.model.setAutofocus("ssn");
        this.model.setErrorState("ssn", SSN_MESSAGE_ERROR);
        this.model.setError(response.data.message);
        return false;
      case 429:
        this.model.setScrollCurrentTabTo("top");
        this.model.setFormLock(true);
        this.model.setSkipValidation(true);
        this.model.setError(SSN_TRACE_MULTIPLE_ERROR);
        return false;
      default:
        this.handleStatusErrors(response.status);
        return false;
    }
  };

  onStatusChange = e => {
    switch (e.current_value) {
      case "initiated":
        break;
      case "review":
        break;
      case "consent":
        break;
      case "processing":
        this.setState({ timeout: true });
        break;
      case "approved":
        break;
      case "pending":
        break;
      case "pending__first_notice":
        break;
      default:
        this.model.setLoading(false);
        this.model.setStep("0");
        this.model.setEdit(true);
        break;
    }
  };

  onConsentEnd = e => {
    if (consentEndTimeOut) clearTimeout(consentEndTimeOut);

    setTimeout(() => {
      if (!e.processing_checks) {
        if (window.sessionStorage)
          window.sessionStorage.setItem(
            "report_token",
            _.isString(this.model.phone)
              ? this.model.phone.slice(-4)
              : _.isString(this.model.ssn)
              ? this.model.ssn.slice(-4)
              : _.isString(this.model.driversLicenseNumber)
              ? this.model.driversLicenseNumber.slice(-4)
              : ""
          );
        this.props.router.push(
          `/consent/${this.props.email_id || this.props.params.email_id}/report`
        );
      } else {
        this.setState({ timeout: true });
      }
      this.setState({ consentEnd: true });
    }, REDIRECT_TO_REPORT_DELAY);
  };

  onChangeChannel = e => {
    const channel = pusherClient.subscribe(`partner_worker.${e.new_uuid}`);
    channel.bind("consent_end", data => {
      this.onConsentEnd(data);
    });
  };

  setConnectionStatus = isOnline => {
    if (typeof isOnline !== "boolean") return;
    this.setState({ isOnline });
  };

  renderLandingPage(loading) {
    return (
      <div>
        <Detector
          render={({ online }) => <div>{online}</div>}
          onChange={connection => this.setConnectionStatus(connection)}
        />
        {this.state.isAsync ? (
          <Pusher
            channel={`partner_worker.${this.model.candidateUUID}`}
            event="consent_end"
            onUpdate={this.onConsentEnd}
          />
        ) : (
          <Fragment>
            <Pusher
              channel={`partner_worker.${this.model.candidateUUID}`}
              event="status_change"
              onUpdate={this.onStatusChange}
            />
            <Pusher
              channel={`partner_worker.${this.model.candidateUUID}`}
              event="change_channel"
              onUpdate={this.onChangeChannel}
            />
          </Fragment>
        )}

        <TurnLoader hidden={!loading} />

        <Provider store={this.model}>
          <EditCandidateDetails
            disclosure={this.model.disclosure}
            rights={this.model.rights}
            terms={this.model.terms}
            stateRights={this.model.stateRights}
            authorization={this.model.authorization}
            details={this.model}
            handleSubmit={this.handleProvideCandidateConsent}
            validateForm={this.validateForm}
            emailId={this.email_id}
            turn_id={this.model.turn_id}
            recheck_id={this.props.recheck_id}
          />
        </Provider>
      </div>
    );
  }

  renderResultPage() {
    if (this.model.consent) {
      return <ConsentProvided details={this.model} />;
    }

    return (
      <>
        <TurnLoader hidden={!this.model.loading} />
        <CandidateReport
          model={this.report}
          onSubmit={this.submitAssent}
          landingModel={this.model}
        />
      </>
    );
  }

  render() {
    if (!this.report.loading || this.model.consent) {
      return this.renderResultPage();
    }

    if (!this.state.validEmail) {
      return <NotFound message={this.state.errorMessage} noLink />;
    }

    if (this.state.timeout) {
      return (
        <ReportsProcessing
          candidateConsentEmailId={this.model.candidateConsentEmailId}
          processing
          redirect_url={this.report.redirect_url}
          worker_status={this.report.worker_status}
        />
      );
    }

    return this.renderLandingPage(this.model.loading);
  }
}

export default observer(BGCLandingPage);
